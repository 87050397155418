.custom-checkbox .ant-checkbox-inner {
  border-color: white !important; /* Set border color to white */
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important; /* Ensure background is white when checked */
  border-color: white !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #7B61FF !important; /* Change checkmark color to purple */
}

.custom-checkbox:hover .ant-checkbox-inner {
  background-color: white !important; /* Ensure background stays white on hover */
  border-color: white !important;
}



/* Hide scrollbars in the entire modal */
.custom-modal .ant-modal-body {
  overflow: hidden;
}

.brand-list {
  position: relative;
  height: 300px; /* Adjust as needed */
  width: 100%; /* Adjust as needed */
  overflow: hidden; /* Hide the scrollbar */
  
}

.brand-list .react-window {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; /* Ensure content can scroll */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.brand-list .react-window::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.brand-list .react-window {
  scrollbar-width: 0px; /* Hide scrollbar */
}

/* Ensure no scrollbars in react-window List */
.filter-list {
  height: 100%; /* Full height of the container */
  width: 100%; /* Full width of the container */
  overflow: hidden; /* Hide overflow within react-window */
}

/* Additional rule to ensure no scrollbars */
.filter-list::-webkit-scrollbar {
  display: none; /* For WebKit browsers (Chrome, Safari) */
}

.filter-list {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}

.custom-checkbox {
  font-weight: 500;
  color: #7B61FF;
  margin-right: 8px;
}

.custom-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #7B61FF !important;
}

.custom-checkbox .ant-checkbox-inner::after {
  border-color: white !important;
}

/* Row styles */
.brand-row {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  background-color: #EFECFF;
  /* Default background color */
  margin-bottom: 10px;
  padding-left: 10px;
  /* Optional: add some padding for better appearance */
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition effect */
  color: #7B61FF;
  /* Default text color */
}

.brand-row:hover {
  background-color: #7B61FF;
  /* Background color on hover */
  color: white;
  /* Text color on hover */
}

.brand-row:hover .brand-row-text {
  color: white !important;
  /* Ensure text color changes to white on hover */
}

.brand-row .brand-row-text {
  color: #7B61FF;
  /* Default text color */
}

/* Button hover styles */
.ant-btn-primary:hover {
  background-color: #6751d8;
  /* Adjust the color to be darker as needed */
  border-color: #6751d8;
}

/* Custom scrollbar styles */
.brand-list {
  overflow-y: auto;
  max-height: 400px;
  /* Increase the height for more spaciousness */
}

.brand-list::-webkit-scrollbar {
  width: 12px;
}

.brand-list::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.brand-list::-webkit-scrollbar-thumb {
  background-color: #7B61FF;
  border-radius: 10px;
  border: 3px solid #f0f0f0;
}

/* For Firefox */
.brand-list {
  scrollbar-width: thin;
  scrollbar-color: #7B61FF #f0f0f0;
}

/* Modal styles */
.custom-modal {
  max-height: 600px;
  overflow: auto;
}

.custom-modal .ant-modal-body {
  padding: 24px;
  /* Increase padding for more spaciousness */
}


.brand-modal-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0 16px;
}

.brand-modal-footer-div {
  display: flex;
  align-items: center;
}

.brand-modal-footer-button {
  color: red;
  background-color: white;
  margin-right: 8px;
}

.brand-modal-footer-badge1 {
  color: black;
}

.brand-modal-footer-badge2 {
  color: #7B61FF;
}

.brand-modal-footer-badge {
  background-color: transparent;
}

.brand-modal-footer-apply-button {
  background-color: #7B61FF;
  border: none;
}

.brand-modal-sub-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.brand-modal-search-bar {
  margin-left: auto;
  width: 40%;
  background-color: #f0f0f0;
  font-weight: bold;
  color: grey;
  border: none;
}

.brand-modal-hr {
  margin-bottom: 10px;
}

.brand-modal-search-prefix {
  color: grey;
}