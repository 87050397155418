.graph-filters-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px; /* You can adjust this if needed */
}

.graph-info {
    display: flex;
    flex-direction: column;
    width: 20%; /* Adjust as necessary for your layout */
    gap: 8px; /* Space between items */
}

.graph-info div {
    display: flex;
    align-items: center;
}

.graph-info div::before {
    content: ''; /* Ensure pseudo-element is visible */
    flex: 0 0 30px; /* Fixed width for the color bar */
    height: 2px; /* Adjust thickness of the color bar */
    margin-right: 8px; /* Space between color bar and text */
}

.graph-info div:nth-child(1)::before {
    border-bottom-color: #7B61FF;
    border-bottom-style: solid;
}

.graph-info div:nth-child(2)::before {
    border-bottom-color: #FF4444;
    border-bottom-style: dotted;
}

.graph-info div:nth-child(3)::before {
    border-bottom-color: #2AC239;
    border-bottom-style: dashed;
}

.graph-days-filters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust for responsiveness */
    gap: 8px; /* Space between items */
    width: 80%;
    height: auto; /* Let content determine height */
    justify-content: flex-start;
}

.special-day-item {
    display: flex;
    align-items: center;
    width: 100%;
    /* Remove any additional height to avoid extra space */
}
