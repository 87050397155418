.my-charts-modal-container {
    padding-bottom: 0 !important;
    position: static !important;
    margin-top:2.6rem;
    width: 600px !important;
    max-width:90%;
}
@media (max-width: 768px) {
    .my-charts-modal-container {
        width: 90% !important; 
        height: auto !important; 
    }
}

.my-charts-header {
    font-size: 24px;
    font-weight: 600;
    color: #288CD5;
}

.my-charts-sub-header {
    font-size: 14px;
    font-weight: 400;
    color: #919191;
}

.radioButtonStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px; 
    width: 110px; 
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    background: #F6F5FF;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    position: relative;
    margin-top: 8px;
}

.selectedChart {
    border: 2px solid #2AC239 !important;
}
.chart-label {
    color: #7B61FF;
    font-weight: 500;
    font-size: 14px;
}

.selectedChartLabel {
    color: #2AC239 !important;
}
.imageStyle {
    margin-bottom: 2px;
    margin-top:8px;
    height: 70px;
    width: 80px;
}

.labelStyle {
    font-size: 16px;
    color: #7B61FF;
    font-weight: 600;
}

.formItemStyle {
    margin-bottom: 8px;
    
}

.backgroundStyle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F1EFFF;
    border-radius: 8px;
    z-index: -1;
}

.data-source-description{
    color: #919191;
    font-size: 14px;
    font-weight: 400;
    margin-top: -6px;
}
.chart-type-description {
    color: #919191;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
}

.cancel-button {
    background: #F5F5F5;
    color: black;
}
.next-button {
    background-color: #7B61FF;
}

.modal-title {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.chart-icon {
    height: 4rem;
    width: 4rem;
}

.modal-header-content {
    display: flex;
    flex-direction: column;
}

.chart-label {
    color: #7B61FF;
    font-weight: 500;
    font-size: 14px;
}

.custom-dropdown .ant-select-selector {
    background: #F6F5FF !important;
    border: 1px solid #D9D9D9; 
}

.dashboard_card_containers {
    border-radius: 25px; /* Adjust the value for desired roundness */
    overflow: hidden; /* Ensures that content doesn't overflow outside the rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow for better visibility */
}

.refresh-button {
    border: transparent; /* Initial transparent border */
    background: transparent; /* Make background transparent */
    padding: 0; /* Remove padding to fit the image */
    cursor: pointer; /* Change cursor to pointer */
    display: flex; /* Use flexbox to center the image */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.delete_button {
    border: transparent; /* Initial transparent border */
    background: transparent; /* Make background transparent */
    padding: 0; /* Remove padding to fit the image */
    cursor: pointer; /* Change cursor to pointer */
    display: flex; /* Use flexbox to center the image */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}


.mycharts {
    display: flex;
    flex-direction: column;
}

.mycharts_top_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mycharts_left_elem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.mycharts_right_elem {
    display: flex;
    align-items: center;
    gap: 12px;
}

.mycharts_bottom_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px; /* Adjust as needed */
}

.mycharts_heading_left_elem {
    display: flex;
    align-items: center;
}

.chart_search_input {
    min-width: 450px; /* Adjust width as needed */
    width: auto; /* Adjust width as needed */
    height: auto; /* Adjust width as needed */
    color: rgb(0, 0, 0); /* Input text color */
}

.chart_search_input input {
    color: rgb(0, 0, 0); /* Input text color */
    background-color: #dfdfdf; /* Medium dark grey */
}

.heading_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns text to the start (left) */
}

.heading_subtext {
    color: #aaaaaa; /* Light gray color for the subtext */
    font-size: 14px; /* Adjust font size as needed */
    margin-top: 5px; /* Space between heading and subtext */
}


.sort-select {
    margin-right: 25px;
    width: 150px;
    border-color: transparent; /* Remove tail on dropdown */
}




