.dashboard_main {
    width: 100%;
    /* height: 100vh; */
    padding: 1rem 0 0 0;
}

.dashboard_navbar {}

.dashboard_sidebar {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 88vh;
}