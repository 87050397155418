.main_container {
    /* border: 2px solid red; */
    display: flex;
    /* gap: 20px; */
    height: 95vh;
    width: 100vw;
    background: #fff;
}

.main_elements {
    /* border: 2px solid black; */
    width: 100%;
    overflow-y: auto;
}