.chart-logo-title {
  display: flex;
  align-items: center; /* Center items vertically */
}

.chart-logo {
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
  margin-right: 8px; /* Space between the logo and the title */
}

.chart-title {
  margin: 0; /* Remove default margin to align properly */
}

.chart-body {             /* Enable Flexbox layout */
  justify-content: center;    /* Center horizontally */
  align-items: center;           /* Set height to full viewport height */
  margin: auto;  
  padding: auto;            /* Center margin (optional, helps for horizontal centering) */
}



.chart-fullscreen-body{
  margin: 20px;
  padding: auto;
}

.pie-chart {
  margin: auto;
  max-width: 600px; 
}

.bar-chart {
  justify-content: center;    /* Center horizontally */
  align-items: center;
  margin-top: 40px;
  padding: auto;  
}


.line-chart {
  justify-content: center;    /* Center horizontally */
  align-items: center;
  margin-top: 40px;
  padding: auto;  
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 430px; /* Set the maximum height */
  overflow-y: auto; 
  padding: 25px;
}

.chart-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.chart-container {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-title {
  color: #7B61FF;
}

.chart-info {
  color: #919191;
  margin-top: 5px;
}

.chart-refresh-time {
  position: absolute; /* Position the element absolutely within its parent */
  bottom: 10px;       /* Adjust the distance from the bottom as needed */
  left: 20px;         /* Adjust the distance from the left as needed */
  font-size: 12px;    /* Adjust font size as needed */
  font-weight: normal; /* Ensure text is not bold */
}

.refresh-label {
  color: rgb(124, 124, 124); /* Light grey color for the label */
}

.refresh-value {
  color: #1E90FF; /* Brighter shade of blue */
  font-weight: 300; /* Thinner text */
  font-family: 'Arial', sans-serif; /* Optional: Use a different font for thinner appearance */
}

.chart-button-no-border {
  border: none !important; /* Remove the border */
  background: transparent; /* Make the background transparent */
  padding: 0; /* Remove default padding */
  display: inline-flex; /* Align items inline */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  width: 30px; /* Allow width to fit the image */
  height: 30px; /* Allow height to fit the image */
  margin: 5px;
}

.chart-button-no-border img {
  display: block; /* Ensure the image fits without extra space */
  width: 100%; /* Optional: scale image to fit button */
  height: auto; /* Maintain aspect ratio */
}

.chart-image {
  max-width: 100%; /* Ensure the image fits within the card */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove extra space */
  margin: 0 auto; /* Center the image horizontally */
}

.chart-card, .add-chart-card {
  flex: 0 0 calc(50% - 10px); /* Two in a row with spacing */
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visual */
  border-radius: 20px; /* Rounded corners */
  min-height: 450px;
  height: calc(85vh - 60px); /* Adjust height based on viewport height */
  display: flex; /* Ensure content is centered */
  flex-direction: column; /* Arrange children vertically */
  justify-content: space-between; /* Ensure spacing between header, body, etc. */
}
/* 
.chart-card{
  margin: 5px;
} */

.add-chart-card {
  margin-left: 15px;
  background-color: #E4F4FF; /* Background color */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  cursor: pointer; /* Change cursor to indicate clickability */
}

.chart-time-info {
  color: rgb(6, 229, 21);
}
