.dashboard_main_container {
    /* height: 165vh; */
    margin: 10px;
    padding: 10px;
    /* background: rgb(135, 148, 110); */
}

.info-icon {
    position: relative;
    display: flex; /* Make sure it behaves like an inline element */
    justify-content: center; 
    align-items: center;
    border-radius: 50%; /* Optional: To make the icon circular */
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
}

.info-icon:hover {
    box-shadow: 0 0 10px rgba(255, 255, 204, 0.8), 0 0 20px rgba(255, 255, 204, 0.5); /* Glowing effect */
}

.tooltip {
    position: absolute;
    margin: 5px;
    top: 20px; /* Adjust this value to move the tooltip up */
    left: 90px;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    color: white;
    padding: 8px;
    border-radius: 4px;
    z-index: 1000; /* Make sure it's above other elements */
    white-space: nowrap; /* Prevent line breaks */
    visibility: visible; /* Initially visible when hovering */
    opacity: 1; /* Fully opaque when visible */
    transition: opacity 0.2s; /* Smooth transition */
}

.info-icon:hover .tooltip {
    visibility: visible; /* Show the tooltip on hover */
    opacity: 1; /* Fully opaque when visible */
}

.note {
    background-color: rgba(255, 255, 204, 0.8); /* Faint yellow background */
    border: 1px solid #e3c60b; /* Slightly darker yellow for the border */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow */
    padding: 15px; /* Padding inside the note */
    margin: 20px 0; /* Space above and below the note */
    font-family: Arial, sans-serif; /* Font family */
    font-size: 14px; /* Font size */
    color: #4a4a4a; /* Dark gray color for the text */
    line-height: 1.5; /* Line height for better readability */
}

.note-label {
    font-weight: bold; /* Bold text for the label */
    color: #e3c60b; /* Darker yellow color for the label */
}


.dashboard_top_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: 50vh; */
    margin-bottom: 20px;
}

.cards_heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.cards_heading h5 {
    font-size: 18px;
    font-weight: 500;
}

.card_heading_left_elem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.card_heading_right_elem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.card_heading_download_btn {
    background: #D9FFE4;
    color: #2AC239;
    border-radius: 20px;
    Padding: 5px 10px 5px 10px;
}

.card_heading_download_btn:hover {
    background: #2AC239;
    color: #FFFFFF;
    cursor: pointer;
}

.card_heading_download_btn>span {
    font-size: 16px;
    font-weight: 500;
}

.cards_heading_select {
    width: 150px;

}


.cards_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cards_heading_select .ant-select-selector {
    border: 1px solid #7B61FF !important;
    border-radius: 20px;
}

.cards_heading_select .ant-select-selector {
    background-color: white;
    border-color: #7B61FF;
    color: #7B61FF;
}

.cards_heading_select .ant-select-arrow,
.cards_heading_select .ant-select-item {
    color: #7B61FF;
}



.dashboard_card_containers {
    box-shadow: 0px 2px 40px 0px #0000001A;

}


.salesanalysis_main_container,
.categoryanalysis_main_container {
    width: 49%;
}

.salesmonitor_main_container {
    width: 100%;
    margin-bottom: 20px;
    height: 40%;
}

.sellingproducts_main_container {
    height: 38%;
    margin-bottom: 20px !important;
}





/*Sales Monitor Graph*/
.sales-monitor-graph {
    position: relative;
    margin: 0 auto;
    padding: 20px;
}

.apexcharts-tooltip {
    background: white !important;
    color: black !important;
    border: 1px solid #e7e7e7 !important;
    box-shadow: 0px 2px 40px 0px #0000001A !important;
}

.apexcharts-tooltip-title {
    font-size: 16px;
    font-weight: bold;
}







/*Selling Products*/

.sellingproducts_table {
    margin-top: 20px;
    height: 51vh;
    overflow: auto;
    border-collapse: separate;
    border-spacing: 0;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class {
    background-color: #EFECFF;
    margin-bottom: 8px;
    border: none;
    /* Remove border and set gap between rows */
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class:first-child td:first-child {
    border-top-left-radius: 20px;
}


.sellingproducts_table .ant-table-tbody>tr.custom-row-class:last-child::after {
    display: none;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class:first-child td:last-child {
    border-top-right-radius: 20px;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class:last-child td:last-child {
    border-bottom-right-radius: 20px;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class td {
    background-color: #EFECFF;
    padding: 16px;
    /* Optional: adjust padding for better spacing */
    border-bottom: none;
    margin-bottom: 8px;
    /* Remove bottom border from cells */
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class:first-child td:last-child {
    border-top-right-radius: 20px;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class:last-child td:last-child {
    border-bottom-right-radius: 20px;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class td:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.sellingproducts_table .ant-table-tbody>tr.custom-row-class+tr.custom-row-class {
    margin-top: 8px;
    /* Add gap between rows */
}

table {
    border-collapse: separate !important;
    border-spacing: 0 8px !important;
}







/*Sales Analysis*/

.sales-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-divider-horizontal {
    margin: 15px 0 !important;
}

.sales-overview div .sales-overview-percentage div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-overview div .sales-overview-percentage .ant-statistic-content {
    background-color: #D9FFE4;
    height: 50%;
    padding: 10px;
    border-radius: 20px;
    width: fit-content;
}

.sales-overview-percentage-suffix {
    display: flex;
    flex-direction: row;
}

.best-selling-brands {
    background-color: #E3F2FD;
    padding: 20px;
    border-radius: 10px;
}

.statistic-title {
    font-size: 18px;
    color: #767676;
    font-weight: 500;
}

.best-selling-brands h3 {
    color: #1976D2;
    margin-bottom: 10px;
}

.brand-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
}

.brand {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}

.brand-percent {
    font-size: 12px;
    margin-bottom: 5px;
}

.brand-details-data {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}

.brand-details-data div {
    width: 33%;
}

.brand-bar {
    width: 100%;
    height: 8px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.brand-name {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    width: 100%;
}

.bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}