.navbar_main {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  /* box-shadow: 0 25px 20px -20px rgba(0, 0, 0, .45); */
  /* height: 10vh; */
  /* padding: 1.4rem; */
  position: relative;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar_left_elem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.navbar_left_elem span {
  max-width: 216px;
  /* width: 172px; */
  height: 29px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #172935;
}

.navbar_vertical_line {
  border: 1px solid #C2C2C2;
  height: 40px;
  margin-right: 5px;
}

.navbar_right_elem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.search-icon {
  cursor: pointer;
  color: #555;
  position: absolute;
  margin-left: 1rem;
  /* Adjust the left position for spacing between icon and input */
}

.search-bar {
  position: relative;
  /* Set position relative on the container */
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
}

.search-input {
  flex: 1;
  width: 423px;
  height: 45px;
  left: 547px;
  border: none;
  outline: none;
  padding: 8px;
  padding-left: 45px;
  /* Adjust the left padding for spacing */
  background: #F3F3F3;
  border-radius: 100px;
  /* Set the background color to grey for all inputs */
}

.navbar_user_detail {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  gap: 5px; /* Add space between children */
}

.navbar_user_detail p:first-child {
  width: auto; /* Or remove this if width is not needed */
  height: auto; /* Allow height to adjust based on content */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px; /* Adjust this value for tighter spacing */
  color: #172935;
}

.navbar_user_detail p:nth-child(2) {
  max-width: auto;
  height: auto; /* Allow height to adjust based on content */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px; /* Keep this as is for the second child */
  color: #288CD5;
}


.custom-submenu {
  max-height: 500px;
  /* overflow-y: scroll; */
}

.navbar_usericon_profilepic, .navbar_dark_mode {
  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.25));
}