.sidebar_main {
    width: 20%;
    height: 85vh;
    /* position: sticky; */
    padding: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    transition: width 0.5s ease;
}

.sidebar_main.closed {
    width: 5%;
  }
  
  .sidebar_toggle {
    /* margin-left: 12rem; */
    display: flex;
    /* align-items: end; */
    justify-content: end;
    cursor: pointer;
  }

.sidebar_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar_top_content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.2rem;
}

.sidebar_bottom_content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.2rem;
}

.sidebar_menu_item {
    display: flex;
    padding: 10px 15px;
    transition: all 0.1s;
    gap: 10px;
    text-decoration: none;
}

.sidebar_main.closed .sidebar_menu_item {
    padding: 10px 10px;
}

.sidebar_main.closed .sidebar_toggle {
    margin-right: 0.7rem;
}

.sidebar_icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }