.allcation_container_main {
    margin: 20px;
    width: 90vw;
}

.allcation_container_main .select-container div:where(.css-dev-only-do-not-override-1r287do).ant-select-single {
    width: 100%;
}

.select-container {
    width: 45%;
}

.graph-container {
    width: 100%;
}

.forecast-table-container {
    width: 100%;
    margin-top: 20px;
    /* Adjust margin as needed */
}

.allcation_container_main .ant-row .select-container .ant-select-outlined .ant-select-selector {

    background: rgb(240, 242, 246);
}

.allcation_container_main .ant-row .select-container label {

    margin-bottom: 10px;
}


.forecast-table-main-container {}