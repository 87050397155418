.forecasted_data_main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* .forecasted_data_heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.forecasted_data_elem1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.forecasted_data_elem1>span {
    font-size: 18px;
    font-weight: 600;
}

.forecasted_data_elem2 {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #D9FFE4;
    color: #2AC239;
    border-radius: 20px;
    Padding: 5px 10px 5px 10px;
}

.forecasted_data_elem2:hover {
    background: #2AC239;
    color: #FFFFFF;
    cursor: pointer;
}

.forecasted_data_elem2>span {
    font-size: 16px;
    font-weight: 500;
} */

.forecasted_data_middle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    /* background: #E8F6FF; */
    color: #7B61FF;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    /* padding: 10px; */
}

.forecasted_data_middle_filters {
    display: flex;
    gap: 5px;
    align-items: center;
}

.forecasted_data_vertical_line {
    height: 19px;
    border: 1px solid #C6C6C6;
}

.ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For IE and Edge */
  }
  
  .ant-table-content::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, Opera */
  }