.no_Data_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.no_data_subcontainer {
    display: flex;
    flex-direction: column;
    gap: 7px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.no_Data_Container img {
    max-width: 100%;
}

.no_data_subcontainer h3 {
    color: #7B61FF;
    font-size: 18px;
}

.no_data_subcontainer p {
    color: #919191;
    font-size: 16px;
}

.add_new_entry_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7B61FF;
    color: white;
}

.add_new_entry_button:hover {
    background: #836afd !important;
}

.add_new_entry_button img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}
