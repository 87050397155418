.user__profile__main {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    /* background: #F5F5F5; */
    /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08); */
}

.user__profile__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
}

.user__profile__elem1 {
    width: 30%;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.user__profile__elem1::before {
    content: "";
    background-image: url('../../assets/SVG/icons/Profile/bgimg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0; /* Ensure top corners are rounded */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80%; /* 20% of the element's height */
    z-index: 0; /* Ensure the background image is behind content */
}

.user__profile__elem2 {
    width: 70%;
    background: #F9F9F9; /* Added background color for visual separation */
    border-radius: 8px;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-sizing: border-box; /* Ensures padding and border are included in the width */
}

/* Additional existing CSS rules */
.user__profile__img__box {
    display: block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.25));
}

.user__profile__img {
    position: relative;
    width: 10rem;
    height: 10rem;
}

.user__profile__img>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.user__profile__img__edit {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
}

.user__profile__img__edit>img {
    width: 100%;
    height: auto;
}

.user__profile__name__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user__profile__name__box span:first-child {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #172935;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.user__profile__name__box span:nth-child(2) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #288CD5;
}

.user__profile__other__detail__box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;
}

.user__profile__detail {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    word-break: break-all;
}

.user__profile__detail span:first-child {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #919191;
}

.user__profile__detail span:nth-child(2) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7B61FF;
}

.user__profile__personal__detail__box1 {
    height: 60%;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
}

.user__profile__personal__detail__box2 {
    height: 60%;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    overflow: auto;

}

.user__detail__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.user__detail__heading>span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.user__detail__heading>button {
    border: none;
    outline-style: none;
    background: transparent;
    cursor: pointer;
}

.user__detail__form {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.user__profile__pwdbtn__box {
    height: 10%;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 1rem;
    padding-bottom: 0.1rem;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 6rem;
}

.col {
    display: flex;
    flex-direction: column;
}

.col label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #919191;
    padding-bottom: 0.5rem;
}

.col input {
    border-radius: 4px;
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #484848;
    padding: 0.2rem 0.5rem;
    outline: none;
}

.user__profile__pwdbtn__box>button {
    border: none;
    cursor: pointer;
    background: #272727;
    border-radius: 8px;
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    opacity: 0.9;
    padding: 0.4rem 0.8rem;
}

.user__profile__pwdbtn__box>button:focus {
    outline: none;
}

.user__profile__button__box {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}

.user__profile__button__box button:first-child {
    border: none;
    cursor: pointer;
    background: #7B61FF;
    border-radius: 8px;
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FEFEFE;
    padding: 0.2rem 1rem;
}

.user__profile__button__box button:nth-child(2) {
    border: none;
    cursor: pointer;
    background: #C6C6C6;
    border-radius: 8px;
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #172935;
    opacity: 0.5;
    padding: 0.2rem 1rem;
}

.user__profile__button__box button:focus {
    outline: none;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .input-wrapper label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .input-wrapper .ant-input-password {
    width: 100%;
  }
  