.delete-modal-footer {
    text-align: right;
  }
  
  .delete-modal-footer button {
    margin-right: 8px;
  }
  
  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  