.user-avatar {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .role-icon {
    display: flex;
    gap: 2px;
    align-items: center;
  }
  
  .role-image {
    width: 17px;
    height: 17px;
  }
  
  .bin-image {
    width: 23px;
    height: 23px;
    margin-right: 8px;
  }
  
  .add-user-button {
    width: 70px;
    height: 40px;
    background-color: #288cd5;
  }
  
  .uma-search-bar {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    position: relative;
  }
  
  .uma-search-input {
    flex: 1;
    height: 45px;
    border: none;
    outline: none;
    padding: 8px;
    padding-left: 45px;
    background: #F3F3F3;
    border-radius: 100px;
  }
  
  .uma-search-icon {
    cursor: pointer;
    color: #555;
    position: absolute;
    margin-left: 1rem;
  }
  
  .uma_main {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    padding: 1rem;
  }
  
  .uma_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .uma_elem1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .uma_elem1_left {
    font-size: 18px;
  }
  
  .uma_elem1_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
  
  .purple-row {
    background-color: transparent;
    border: none;
  }
  
  .add-user-modal-content {
    max-height: 400px; /* Set a max height for the modal content */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 1rem;
  }