.ResizeHandleOuter {
    background-color: #f0f0f0;
    cursor: col-resize;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ResizeHandleInner {
    padding: 8px;
}

.Icon {
    width: 16px;
    height: 16px;
}