.Demand_Analysis_filter {
    /* background-color: aqua;  */
    /* border: 2px solid red; */
    /* margin-top: 4px;
    border-radius: 16px;
    margin-left: 20px;
    margin-right: 15px; */
}

.top1 {
    display: flex;
    justify-content: space-between;
    /* margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px; */
    align-items: center;
}

.top1_left_filterContainer {
    display: flex;
    gap: 10px;
}

.filterContainer_para {
    font-size: 18px;
    font-weight: 500;

}

.top1_right {
    display: flex;
    gap: 25px;
}

.top1_right button:nth-of-type(1) {
    border-radius: 15px;
    padding: 2px 7px;
    background-color: #DCF0FE;
    color: #288CD5;
    font-size: 16px;
    font-weight: 500;
    place-content: center;
    display: flex;
    gap: 6px;
    border: none;
    align-items: center;
}

.top1_rightBtn {
    border-radius: 15px;
    padding: 2px 7px;
    background-color: #DCF0FE;
    color: #288CD5;
    font-size: 16px;
    font-weight: 500;
    place-content: center;
    display: flex;
    gap: 6px;
    border: none;
    align-items: center;
}

.top1_right button:nth-of-type(2) {
    border-radius: 15px;
    padding: 5px 7px;
    color: red;
    border: none;
    font-size: 16px;
    font-weight: 500;
    place-content: center;
    display: flex;
    gap: 6px;
    align-items: center;
    background-color: white;
}


.clearAll_Button:hover {
    background-color: #FFE8E8 !important;
}

.footer-btn {
    background-color: #3288CD;
    width: 8em;
    border-radius: 12px;
}

.top2 {
    display: flex;
    flex-direction: row;
    margin-top: 17px;
    gap: 0px;
    /* flex-wrap: wrap; */

    button {
        padding: 12px 0px 12px 0px;
        width: 90%;
        border-radius: 9px;
        border: none;
        margin-top: 4px;
        text-align: left;
        padding-left: 11px;
        color: #919191;
        font-size: 15px;

        img {
            position: relative;
            left: 25rem
        }
    }
}

.top2_field {
    width: 30%;
}

.noHover:hover,
.noHover:active,
.noHover:focus {
    background-color: initial !important;

}

.custom-modal .ant-modal-title {
    color: #288CD5;
    font-size: 20px;
    font-weight: 500;
}

.Modal_title {
    display: flex;
    align-items: center;
    gap: 6px
}

.col-btns:hover {
    background-color: white;
    color: green;
}

.col-btns {
    width: 100%;
    background-color: #DCF0FE;
    transition: background-color 0.3s ease;
}

.col-btns:focus {
    color: #2AC239;
    background-color: white;
}