.upload-profile-pic-modal .ant-upload-drag-icon {
    margin-bottom: 16px;
  }
  
  .upload-profile-pic-modal .ant-upload-text {
    color: #7B61FF;
  }
  
  .upload-profile-pic-modal .ant-upload-hint {
    font-size: 14px;
  }
  
  .upload-profile-pic-modal-title {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #7B61FF;
    margin-bottom: 15px;
  }
  
  .upload-profile-pic-modal-cancel-btn {
    background: #C6C6C6;
    margin-top: 15px;
    margin-right: 5px;
    color: #172935;
    opacity: 0.5;
  }
  
  .upload-profile-pic-modal-save-btn {
    background: #7B61FF;
    margin-top: 15px;
  }
  