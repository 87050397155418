.onboarding_conatiner {
    display: flex;
    flex-direction: column; /* Ensure vertical stacking */
    gap: 16px; /* Add spacing between items */
    h1{
        font-weight: 600;
        font-size: 20px;
        line-height: 24.2px
    }    
}


.purple-row {
    background-color: #F6F5FF;
    
  }

  .column-padding {
    /* padding: 50px 50px;  */
  
    padding-left: 20rem;
    
  }


.onboarding_conatiner__dropdown{
    padding: 2px 25px 3px 25px;
display: flex;
align-items: center;
justify-content: center;
background: #dcedff;
border-radius: 10px;
cursor: pointer;
font-weight: 400;
font-size: 18px;
line-height: 25px;
color: #242325;

}
.onboarding__div2{
    display: flex;
    gap: 15px;
    align-items: center;
}

.onboarding_page_is_active {
    display: flex;
    justify-content: center;
    align-items: center; /* Optional: Center vertically if needed */
  }