.assortment-planning-table .ant-table-tbody>tr.hover-row:hover>td {
  background: #EFECFF !important;
}

.assort_plan_search_icon {
  cursor: pointer;
  color: #555;
  position: absolute;
  margin-left: 1rem;
  /* Adjust the left position for spacing between icon and input */
}

.assort_plan_search_bar {
  position: relative;
  /* Set position relative on the container */
  display: flex;
  align-items: center;
  /* padding: 8px; */
  border-radius: 4px;
}

.assort_plan_search_input {
  /* flex: 1; */
  width: 423px;
  height: 40px;
  left: 547px;
  border: none;
  outline: none;
  padding: 8px;
  padding-left: 45px;
  /* Adjust the left padding for spacing */
  background: #F3F3F3;
  border-radius: 5px;
  /* Set the background color to grey for all inputs */
}