.content{
    color:#7B61FF;
    margin-top: 10px;
}
.confirm-text{
    color:#919191;
    font-size:18px;
}
.cancel-button {
    background: #F5F5F5;
    color: black;
}
.confirm-button{
    background-color:red;
    color:white;
} 
.chart-value{
    color:black;
}