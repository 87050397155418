/* OnboardingEntryModal.css */

/* Country Code and Dropdown Styles */
.country-code-style, .custom-dropdown {
    background-color: #F6F5FF;
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
}

.country-code-style {
    width: 125px;
    border-radius: 5px 0 0 5px;
}

.custom-dropdown {
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 10px;
}

/* Modal Body and Content Styles */
.modal-body {
    max-height: 500px;
    overflow-y: auto;
}

.modal-content {
    padding: 20px;
}

/* Input, Select, and Contact Styles */
.input-style, .select-style, .contact-number-style {
    width: 400px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #C9BFFF;
    background-color: #F6F5FF;
}

.contact-number-style {
    height: 50px;
    display: flex;
    align-items: center;
}

/* Label and Button Styles */
.label-style {
    color: #7B61FF;
}

.button-style {
    background-color: #7B61FF;
    color: white;
    float: right;
}

/* Separator Style */
.separator-style {
    height: 25px;
    width: 1px;
    background-color: #C6C6C6;
    margin-right: 10px;
}
