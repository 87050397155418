.chart-modal-container { 
    padding-bottom: 0 !important;
    position: relative; 
    width: 600px !important;
    max-width:90%;
    z-index: 5;
}

@media (max-width: 768px) {
    .chart-modal-container {
        width: 90% !important;
        height: auto !important; 
    }
}

.chart-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.icon {
    height: 1.5rem;
    margin-right: 0.2rem;
    color: #7B61FF;
}

.graph-chart-title {
    color: #7B61FF;
    font-size: 18px;
    margin-right:17rem;
}
.line-chart-title {
    color: #7B61FF;
    font-size: 18px;
    margin-right:16rem;
}

.axis-selection{
    margin-bottom: 6px;
}
.duration-selection {
    margin-bottom: 1rem;
}

.axis-label,
.duration-label {
    color: black;
    font-weight: 600;
    font-size: 16px;
    margin-top: 3px;
}

.axis-description,
.duration-note {
    color: grey;
    margin-bottom: 5px;
    font-size: 14px;
}

.axis-select,
.duration-select {
    width: 100%;
    margin-top: 8px;
}

.back-button {
    background-color: #EFEFEF;
    color: black;
}
.create-button{
    background-color: #7B61FF;
}

.modal-title {
    display: flex;
    gap: 1rem;
}

.modal-icon {
    height: 3.3rem;
}

.modal-header {
    display: flex;
    flex-direction: column;
}

.modal-header-title {
    color: #288CD5;
    font-size: 24px;
    font-weight: 600;

}

.modal-header-description {
    color: #919191;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.36px;
}
