.apexcharts-xaxis-title {
    font-weight: 300; /* Reduced font weight for x-axis title */
    color: #7B61FF; /* Corrected to 'color' */
    
}

.apexcharts-yaxis-title {
    font-weight: 300; /* Reduced font weight for y-axis title */
    color: #7B61FF; /* Changed 'font-color' to 'color' */
    /* top: 20px; Adjust vertical position */

}