
.login {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    /* border: 2px solid red; */
    /* justify-content: center; */
    align-items: center;
    background: #EFECFF;
}

.login-content-main-logo{
    /* margin-bottom: 30px; */
    /* border: 2px solid red; */
    margin-top: 100px;
}

.login-content-main-logo>img {
    width: 200px;
    height: 80px;
}

.login-content-header-logo {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin-top: 100px;
    margin-right: 20px;


}

.login-content-header-logo>img {
    width: 200px;
    height: 80px;
}
.login-logo-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

.login-content {
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
    align-items: center;
    background: #F8F8F8;    
}

.logo-forecast img{
    background: linear-gradient(217.19deg, #7B61FF 5.66%, #4A3A99 95.25%);
    width: 40px;
    margin-right: 27px;
    /* margin-top: 20px; */
    padding: 5px;
    border-radius: 5px;
}

.login-content-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-family: Quicksand, sans-serif;
}

.login-form {
    width: 90%;
}

.username_box {
    margin-top: 40px;
}

.login-content-footer-centreside {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.login-content-footer-centreside>p {
    font-weight: 500;
}

.login-content-footer-rightside {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.login-content-footer-rightside>p {
    font-weight: 500;
}